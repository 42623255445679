import React, { useState } from "react"

export const FeelingPopUpContext = React.createContext()

const FeelingPopUpProvider = ({ children }) => {
  const [isFeellingPopUpOpen, setIsFeellingPopUpOpen] = useState(false)

  const contextValues = {
    isFeellingPopUpOpen,
    setIsFeellingPopUpOpen,
  }

  return (
    <FeelingPopUpContext.Provider value={contextValues}>
      {children}
    </FeelingPopUpContext.Provider>
  )
}

export default FeelingPopUpProvider
