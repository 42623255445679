// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-action-plan-js": () => import("./../src/pages/action-plan.js" /* webpackChunkName: "component---src-pages-action-plan-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-questions-js": () => import("./../src/pages/questions.js" /* webpackChunkName: "component---src-pages-questions-js" */)
}

