import React, { useState, useEffect } from "react"
import { storeUniqueId } from "helpers/storeUniqueId"
import Cookies from "js-cookie"

export const UserContext = React.createContext()

const UserProvider = ({ children }) => {
  const [user, setUser] = useState({})
  const [responses, setResponses] = useState([])

  const storeResponse = (id, value) => {
    let updatedResponses = responses.filter(response => response.id !== id)
    setResponses([...updatedResponses, { id: id, results: value }])

    //set highRisk on this slide
    if (
      id === "2.1.2.9-talking-about-it-yes-no-cards" &&
      value.includes("yes") &&
      !user.ipvHR
    ) {
      setUser({ ...user, ipvHR: true })
    }
  }

  const storeBranch = branch => {
    setUser({ ...user, branch: branch })
  }

  const storeWorriedAbout = worriedAbout => {
    setUser({ ...user, worriedAbout: worriedAbout, ipvHR: false })
  }

  const contextValues = {
    user,
    responses,
    storeResponse,
    storeBranch,
    setUser,
    storeWorriedAbout,
  }

  useEffect(() => {
    setUser({ ...user, responses: responses })
  }, [responses])

  useEffect(() => {
    storeUniqueId("uom-ms-id", 4)
  }, [])

  return (
    <UserContext.Provider value={contextValues}>
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
