const partnerBehavioursYesNoCardOptions = [
  {
    primaryText: `They seem afraid of their partner or overly anxious to please them.`,
  },
  {
    primaryText: `They have stopped seeing friends or family, or cut phone conversations short when their partner is in the room.`,
  },
  {
    primaryText: `Their partner often criticizes them or humiliates them in front of other people.`,
  },
  {
    primaryText: `They say their partner pressures or forces them to do sexual things.`,
  },
  {
    primaryText: `Their partner often orders them about or makes all the decisions (for example, their partner controls all the money, tells them who they can see and what they can do).`,
  },
  {
    primaryText: `They often talks about their partner’s ‘jealousy’, ‘bad temper’ or ‘possessiveness’.`,
  },
  {
    primaryText: `They have become anxious or depressed, lost confidence, or are unusually quiet.`,
  },
  {
    primaryText: `They have physical injuries (bruises, broken bones, sprains, cuts etc). They may give unlikely explanations for physical injuries.`,
  },
  {
    primaryText: `After they have left the relationship, their partner is constantly calling them, harassing them, following them, coming to their house or waiting outside.`,
  },
]

const FeelingsYesNoCardsData = [
  {
    primaryText: `Frustration or anger that your friend can’t or won’t leave the abusive relationship`,
  },
  { primaryText: `Guilt that you can’t do more` },
  { primaryText: `Worry or anxiety about your friend’s wellbeing` },
  { primaryText: `Fear for your friend’s, or your own safety` },
  { primaryText: `Feeling out of your depth and helpless` },
  { primaryText: `Not knowing what to do or where to go for help` },
]
const sliderCardsData = [
  {
    primaryText: `How unhealthy do you think your friends relationship is with their partner or ex-partner?`,
    options: [
      `Very unhealthy or abusive`,
      `Somewhat unhealthy`,
      `Healthy Relationship`,
    ],
  },
  {
    primaryText: `How unsafe is your friend in the relationship is with their partner or ex-partner?`,
    options: [`Very unsafe`, `Somewhat unsafe`, `Safe`],
  },
  {
    primaryText: `How afraid does your friend feel in the relationship is with their partner or ex-partner?`,
    options: [`Very afraid`, `Somewhat afraid`, `Not afraid`],
  },
]

const OnlineBehaviourCheckboxOptions = [
  `Threatened on a digital device to physically hurt your friend’s family or friends.`,
  `Threatened on a digital device to physically hurt your friend.`,
  `Shared a nude photo of your friend without their permission.`,
  `Threatened to distribute nude images of your friend.`,
  `Threatened to distribute embarrassing information about your friend.`,
  `Threatened on a digital device to hurt themselves if your friend didn’t do what they wanted.`,
  `Took a photo or video of your friend naked or during sex without their permission.`,
  `Started a social networking page to post negative information about your friend.`,
  `Monitored your friend’s internet activity using software.`,
  `Told your friend on a digital device to hurt themselves.`,
]

export const friendRelationshipFlowData = [
  {
    template: `ContentScreen`,
    id: `2.3.2-health-relationship-content`,
    data: {
      textLeft: `
      <h2>What is a healthy relationship?</h2>
      <p>It can be difficult from the outside to tell whether a relationship is healthy or unhealthy.</p>`,
      textRight: `
      <p>Generally, a healthy relationship involves: </<p>
      <ul>
      <li>Mutual trust</li>
      <li>Support and open communication</li>
      <li>Commitment and honesty</li>
      <li>Respect</li>
      <li>Freedom to make your own decisions</li>
      </ul>
      <p>An unhealthy relationship involves:</p>
      <ul>
      <li>Unsupportive or disrespectful behaviour</li>
      <li>Pain, fear or harm</li>
      <li>Controlling behavior</li>
      </ul>`,
    },
  },
  {
    template: `SliderCardsScreen`,
    id: `2.3.2-healthy-relationship-slider-cards`,
    data: {
      textLeft: `
      <h2>What is a healthy relationship?</h2>
      <p>Most couples have arguments or fights, but this does not necessarily mean your friend’s relationship is unhealthy.</p>
      <p>However, when one person seems to be feeling unsafe or afraid in the relationship, this is usually a sign that there are serious problems.</p>
      `,
      textRight: `<p>It can be helpful to assess your friend's relationship using the scales below.</p><p class='instructions'>Drag the scale from left to right to make your selection.</p>`,
      slidersData: sliderCardsData,
    },
  },
  {
    template: `YesNoCardsScreen`,
    id: `2.3.2.1-partner-behaviours-yes-no-cards`,
    data: {
      popUp: {
        tall: true,
        type: `feedback`,
        msg1: `<h2>Feedback</h2><p>It sounds like your friend might be in an unhealthy relationship. Controlling behaviours are often a sign of abuse within a relationship, particularly if your friend seems anxious, depressed, or worried.</p> <p>It's also never OK for someone to be tricked, pressured or forced into having sex in a relationship.</p><p>The next pages of this website will suggest some ways that you can support your friend during this difficult time, as well as taking care of your own wellbeing.</p>`,
        msg2: `<h2>Feedback</h2><p>It isn’t clear whether your friend’s relationship is healthy or not. Although you haven’t seen any obvious signs, sometimes you might have a sense that something is ‘wrong’ without really being able to put your finger on why. Often your ‘gut feeling’ can be correct.</p><p>The next pages of this website will suggest some ways you could start a conversation with your friend to try and find out more about whether their relationship is healthy.`,
      },
      textLeft: `<h2>Partner Behaviours</h2>
        <p>Sometimes it’s hard to know the signs of an abusive relationship. Here are some behaviours that people sometimes experience from a partner or ex-partner.</p>
        <p>Please select <span>'yes'</span> if you think your friend has experienced any of these.</p>
        `,
      highCards: true,
      cardsData: partnerBehavioursYesNoCardOptions,
    },
  },
  {
    template: `CenteredContentScreen`,
    id: `2.3.2.1-friend-emotional-abuse`,
    data: {
      heading: `Emotional Abuse`,
      body1: `
        <p>People often think that an abusive relationship involves hitting, kicking and other physical violence. But a relationship can be abusive even if there is no physical violence at all.</p>
        <p>Emotional abuse is a non-physical type of violence in relationships that includes things like: </p>
        <ul>
        <li>Controlling behavior – telling someone what they can and can’t do, where they can go, who they can see, how they should dress or act</li> 
        <li>Putting someone down all the time – for example calling them ugly, stupid, or worthless</li> 
        <li>Using verbal aggression and intimidation</li>
        <li>Forcing someone to show their phone or email to see who has been calling, texting or emailing them</li> 
        <li>Public embarrassment or deliberate criticism of someone in front of friends, colleagues or family</li>
        <li>Blackmail, manipulation, or ‘freezing out’ as a way of punishing someone</li>
        <li>Denying that things are happening or re-writing history (‘gaslighting’)</li>
        <li>Stalking or following someone</li>
        <li>Using someone’s partner’s visa status as a threat to get them to do something</li>
        <li>Trying to manipulate someone into getting pregnant or having an abortion</li>

        </ul>
        `,
    },
  },
  {
    template: `ContentScreen`,
    id: `2.3.2.2-online-behaviours-pre`,
    data: {
      textLeft: `
      <h2>Online behaviours</h2>
      <p>Sometimes things happen online that make people feel scared,
      embarrassed or unsafe.</p>`,
      textRight: `<p>This section of the website is focusing on things a current or ex-partner might have done to your friend online or using a digital device (e.g. phone).</p>`,
    },
  },
  {
    template: `CheckboxScreen`,
    id: `2.3.2.3-online-behaviours-checkbox`,
    data: {
      popUp: {
        tall: true,
        type: `feedback`,
        msg1: `<h2>Feedback</h2>
               <p>It sounds like your friend may be experiencing what is called 'image-based abuse'.</p>
               <p>Image-based abuse happens when someone shares (or threatens to share) a naked or intimate image of someone online without their consent. It is a type of sexual violence, and it is illegal. There are some things your friend can do to get the images removed and to take action against the person responsible if they wish.</p>
               <p>At the end of this website we will suggest some organisations who can help.</p>
               `,
        msg2: `<h2>Feedback</h2>
               <p>It sounds like your friend is in an unhealthy relationship, and their partner is using digital devices to intimidate, harass or control them.</p>
               <p>This is a form of partner violence, and it can cause negative physical and mental health issues, even when the abuse happens online.</p>
               <p>A bit later this website will suggest some resources you can give your friend to help them decide what they would like to do.</p>
              `,
      },
      heading: `Online Behaviours`,
      textRight: `<p>Below is a list of things that your friend might have experienced from a partner online.</p>
      <span>Please choose all the options that apply.</span>`,
      columns: 3,
      options: OnlineBehaviourCheckboxOptions,
    },
  },
  {
    template: `CenteredContentScreen`,
    id: `2.3.2.5-what-can-i-do`,
    data: {
      heading: `What can I do?`,
      body1: `
      <h3>Starting the conversation</h3>
      <p>It isn’t easy to raise the topic of abuse. You may be worried that your friend will be angry, embarrassed or upset if you ask about what’s going on in their relationship. But if you ask them in a sensitive and caring way, they are likely to appreciate the opportunity to talk about what’s happening and their feelings about it. You could try saying:</p>
      <ul>
      <li>'I’m worried about your relationship.'</li>
      <li>'Is everything OK with you and [partner] at the moment?'</li>
      <li>'I’ve noticed you seem really stressed whenever you’re around [partner].'</li>
      </ul>

      <p>Remember, your friend will talk to you <span>if and when they are ready</span>. Pressuring or forcing them to try to talk to you is not helpful.</p>
      `,
      body2: `
          <h3>What can I do if a friend discloses to me?</h3>
          <p>How you respond to a friend who tells you they are experiencing violence in a relationship can have a big impact on them. As a friend, the best thing you can do is listen, be on their side and help them work out how to become safer. It’s very important not to be judgemental or to try to take over their decisions or 'fix' the problem. Don’t assume that leaving the abusive partner is the best solution - standing up to someone who is abusive can be very difficult and even dangerous. There’s a useful mnemonic called <span>LIVES</span> that can help.</p>
          <ul>
          <li><span>L</span>isten to them (without judging or telling them what to do)</li>
          <li><span>I</span>nquire about their needs (ask what THEY want and need)</li>
          <li><span>V</span>alidate their experiences (tell them this is something that does happen to students and that it is not acceptable)</li>
          <li><span>E</span>nhance safety (check that they are not in immediate danger, discuss whether there is anything else they can do to stay safer)</li>
          <li><span>S</span>upport and follow up (let your friend know that you are there for them, and check-in regularly to make sure they’re OK. A bit later, this website will suggest some helpful resources you can give your friend.</li>
          </ul>
          `,
    },
  },
  {
    template: "YesNoCardsScreen",
    id: "2.3.2.6-feelings-yes-no-cards",
    data: {
      textLeft: `<h2>Feelings</h2>
        <p>Supporting a friend who is experiencing violence from a partner can be stressful. Here are some common feelings.</p>
        <p>Please select <span>'yes'</span> if you are feeling any of these:</p>
        `,
      cardsData: FeelingsYesNoCardsData,
    },
  },
  {
    template: "VideoScreen",
    id: "2.3.2.6-video-1",
    data: {},
  },
  // {
  //   template: 'VideoScreen',
  //   id: '2.3.2.6-video-2',
  //   data: {
  //     double: true,
  //   },
  // },
]
