import React from "react"
import UserProvider from "./src/context/UserContext"
import FlowProvider from "./src/context/FlowContext"
import PopUpContext from "./src/context/PopUpContext"
import FeelingPopUpContext from "./src/context/FeelingPopUpContext"

import { ApolloProvider } from "@apollo/react-hooks"
import ApolloClient from "apollo-boost"
import fetch from "isomorphic-fetch"

const client = new ApolloClient({
  fetch,
  ssrMode: typeof window === "undefined",
  uri: process.env.GATSBY_GRAPHQL_ENDPOINT,
})

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <PopUpContext>
      <UserProvider>
        <FlowProvider>
          <FeelingPopUpContext>{element}</FeelingPopUpContext>
        </FlowProvider>
      </UserProvider>
    </PopUpContext>
  </ApolloProvider>
)
