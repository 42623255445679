import React, { useContext, useState, useEffect } from "react"
import { UserContext } from "context/UserContext.js"
import { PopUpContext } from "context/PopUpContext.js"
import { getCasScore } from "utils/getCasScore"
import { victimSexFlowData } from "utils/victimSexFlowData"
import { victimRelationshipFlowData } from "utils/victimRelationshipFlowData"
import { perpetratorRelationshipFlowData } from "../utils/perpetratorRelationshipFlowData"
import { perpetratorSexFlowData } from "utils/perpetratorSexFlowData"
import { friendSexFlowData } from "utils/friendSexFlowData"
import { friendRelationshipFlowData } from "utils/friendRelationshipFlowData"
import useMedia from "hooks/useMedia.js"

// import history from "utils/history"
import { navigate } from "gatsby"
import Cookies from "js-cookie"

import { useMutation } from "@apollo/react-hooks"
import gql from "graphql-tag"

const SEND_EVENT_TRACKER = gql`
  mutation CreateTrackMySafetyEvent(
    $event_name: String!
    $value: String!
    $userID: String!
  ) {
    createTrackMySafetyEvent(
      input: {
        clientMutationId: "Event Tracker"
        event_name: $event_name
        value: $value
        userID: $userID
      }
    ) {
      data
      success
    }
  }
`

export const FlowContext = React.createContext()

const initialScreens = [
  {
    template: "IntroScreen",
    id: "intro-screen",
    noProgress: true,
    data: {
      noPagination: true,
    },
  },
  {
    template: "WorriedAboutScreen",
    id: "worried-about-screen",
    noProgress: true,
    data: {
      noPagination: true,
    },
  },
]

const FlowContextProvider = ({ children }) => {
  const { user, setUser, storeWorriedAbout } = useContext(UserContext)
  const { isPopUpOpen, togglePopUp } = useContext(PopUpContext)
  const [currentFlowData, setCurrentFlowData] = useState(initialScreens)

  const [currentScreen, setCurrentScreen] = useState(currentFlowData[0])
  const currentScreenData = currentScreen && currentScreen.data
  const [flowDisabled, setFlowDisabled] = useState(false)
  const currentScreenIndex = currentFlowData.indexOf(currentScreen)
  const screensLength = currentFlowData.length
  const animationDuration = 1000

  const firstPage = currentScreenIndex === 0
  const lastPage = screensLength > 2 && currentScreenIndex === screensLength - 1
  const [createTrackMySafetyEvent] = useMutation(SEND_EVENT_TRACKER)

  const isMobile = useMedia(
    // Media queries
    ["(max-width: 1023px)"],
    //options
    [true],
    // default
    false
  )

  const trackEvent = (event_name, value) => {
    const uniqueId = Cookies.get("uom-ms-id")

    createTrackMySafetyEvent({
      variables: {
        event_name: event_name,
        value: value,
        userID: uniqueId,
      },
    })
  }

  const previousScreen = () => {
    setCurrentScreen(currentFlowData[currentScreenIndex - 1])
  }

  const nextScreen = () => {
    if (flowDisabled) {
      return false
    }
    setCurrentScreen(currentFlowData[currentScreenIndex + 1])
  }

  const goToScreen = id => {
    const specificScreen = currentFlowData.find(screen => screen.id === id)
    setCurrentScreen(specificScreen)
  }

  const scrollTo = id => {
    document.getElementById(id).scrollIntoView()
  }

  const resetFlow = () => {
    setCurrentScreen(currentFlowData[0])
  }

  const checkHasYes = results => {
    if (results.includes("yes")) {
      return true
    }
  }
  const checkHasMarked = results => {
    if (
      results.includes("Pressured you online to engage in sexual acts") ||
      results.includes(
        "Pressured you online to send nude image(s) of yourself"
      ) ||
      results.includes(
        "Made you disclose online conversation(s) you had with another person(s) to them"
      ) ||
      results.includes(
        "Shared a nude photo of your partner without their permission?"
      ) ||
      results.includes(
        "Threatened to distribute nude images of your partner?"
      ) ||
      results.includes(
        "Took a photo or video of your partner naked or during sex without their permission?"
      ) ||
      results.includes(
        "Shared a nude photo of your friend without their permission?"
      ) ||
      results.includes(
        "Threatened to distribute nude images of your friend?"
      ) ||
      results.includes(
        "Took a photo or video of your friend naked or during sex without their permission?"
      )
    ) {
      return true
    }
    // console.log("false")
  }

  const evaluateFeedback = id => {
    const currentResponse = user.responses.find(response => response.id === id)
    const skipUnansweredScreen = user.ipvHR
    const popUpData = currentScreenData.popUp
    let msg = popUpData && popUpData.msg1

    if (currentResponse) {
      const currentResults = currentResponse.results
      const answersExist = currentResults.length > 0
      const containsYes = checkHasYes(currentResults)
      const itIsMarked = checkHasMarked(currentResults)
      //!ANSWERS EXIST
      if (answersExist) {
        console.log(currentResults)
        //INITIATING POPUP TO OPEN BASED ON ANSWERS
        if (!isPopUpOpen && popUpData) {
          //talking about it yes / no
          if (id === "2.1.2.9-talking-about-it-yes-no-cards" && !containsYes) {
            msg = popUpData.msg2
          }

          if (
            id === "2.3.2.1-partner-behaviours-yes-no-cards" &&
            !containsYes
          ) {
            msg = popUpData.msg2
          }
          if (id === "2.1.2.5-online-behaviours-checkbox" && !itIsMarked) {
            msg = popUpData.msg2
          }
          if (id === "2.2.2.5-online-behaviours-checkbox" && !itIsMarked) {
            msg = popUpData.msg2
          }
          if (id === "2.3.2.3-online-behaviours-checkbox" && !itIsMarked) {
            msg = popUpData.msg2
          }

          //unwanted sexual experiences grid
          if (
            id.includes("unwanted-sexual-experiences-checkbox") &&
            currentResults.length === currentScreen.data.options.length
          ) {
            nextScreen()
            return true
          }

          if (id === "2.1.2.1-partner-behaviours-composite-scales") {
            const casScore = getCasScore(currentResults)

            if (casScore === "SC") {
              msg = popUpData.msg2
              setUser({ ...user, ipvHR: true })
            } else if (casScore === "PE") {
              msg = popUpData.msg3
            } else if (casScore === "PO") {
              msg = popUpData.msg4
            } else if (casScore === "EO") {
              msg = popUpData.msg5
            }
          }

          //go to highRisk screen
          if (id === "2.2.2.1-relationship-behaviours") {
            const highRisk = checkHasYes(currentResults.slice(8, 15))
            if (highRisk) {
              goToScreen("2.2.2.2-feedback-high-risk")
            } else if (containsYes) {
              nextScreen()
            } else {
              togglePopUp(true, popUpData.type, null, msg)
            }
            return true
          }

          togglePopUp(true, popUpData.type, null, msg)
          return true
        }

        //!WHEN POP UP IS OPEN DIRECT TO SOMEWHERE WHICH IS ISN'T THE NEXT SCREEN
        if (isPopUpOpen) {
          if (id === "2.2.2.1-relationship-behaviours") {
            goToScreen("2.2.2.3-relationship-behaviours")
          } else {
            nextScreen()
          }
          return true
        }
      }

      //!SCREENS WHERE USER DID NOT RESPOND
      //skip screens
      if (id === "2.2.2.1-relationship-behaviours" && !answersExist) {
        if (!isPopUpOpen) {
          togglePopUp(true, popUpData.type, null, msg)
        } else {
          goToScreen("2.2.2.3-relationship-behaviours")
        }
        return true
      }

      // skip talkingAbout feedback screen if no boxes ticked

      if (id.includes("talking-about-it-checkbox") && !answersExist) {
        if (currentFlowData[currentScreenIndex + 2]) {
          if (skipUnansweredScreen) {
            getActionPlan()
          } else {
            setCurrentScreen(currentFlowData[currentScreenIndex + 2])
          }
        } else {
          getActionPlan()
        }
        return true
      }
    }

    //!SCREENS WITH NO USER RESPONSES

    if (id === "2.1.2.9-talking-about-it-feedback") {
      if (skipUnansweredScreen) {
        getActionPlan()
      } else {
        nextScreen()
      }
      return true
    }

    //skip screens
    if (id === "2.2.2.2-feedback-at-risk") {
      goToScreen("2.2.2.3-relationship-behaviours")
      return true
    }

    // //skip online behaviors
    // if (id.includes("online-behaviours-screening")) {
    //   goToScreen(currentScreenData.button2.screenId)
    //   return true
    // }

    return false
  }

  const getActionPlan = () => {
    const branch = user.branch
    const worriedAbout = user.worriedAbout
    const highRisk = user.ipvHR
    let id = 0
    if (branch === "victim") {
      if (worriedAbout === "sexual-experience") {
        id = 1
      } else if (worriedAbout === "relationship" && highRisk) {
        id = 2
      } else if (worriedAbout === "relationship") {
        id = 3
      }
    }

    if (branch === "perpetrator") {
      if (worriedAbout === "sexual-experience") {
        id = 4
      } else if (worriedAbout === "relationship") {
        id = 5
      }
    }

    if (branch === "friend") {
      if (worriedAbout === "sexual-experience") {
        id = 6
      } else if (worriedAbout === "relationship") {
        id = 7
      }
    }
    trackEvent("end", "end")
    navigate(`/action-plan/${id}`)
  }

  const nextMove = () => {
    if (!lastPage) {
      //execute a specific action (popUp / skip) based on the currentResponses of the currentScreen
      const specificAction = evaluateFeedback(currentScreen.id)
      if (!specificAction) {
        nextScreen()
      }
    } else {
      // action plan shit
      getActionPlan()
    }
  }

  const goBack = () => {
    if (!firstPage) {
      // if (currentScreen.id === "2.1.2.7-feelings-yes-no-cards") {
      // goToScreen("2.1.2.4-online-behaviours-screening")
      // } else
      if (
        currentScreen.id === "2.2.2.2-feedback-high-risk" ||
        currentScreen.id === "2.2.2.3-relationship-behaviours"
      ) {
        goToScreen("2.2.2.1-relationship-behaviours")
      }
      // else if (currentScreen.id === "2.2.2.7-healthy-communication") {
      //   goToScreen("2.2.2.4-online-behaviours-screening")
      // } else if (currentScreen.id === "2.3.2.5-what-can-i-do") {
      //   goToScreen("2.3.2.2-online-behaviours-screening")
      // }
      else {
        previousScreen()
      }
    }

    const lastResponses = user.responses[user.responses.length - 1]

    if (
      lastPage &&
      lastResponses.results.length === 0 &&
      lastResponses.id.includes("talking-about-it-checkbox")
    ) {
      setCurrentScreen(currentFlowData[currentScreenIndex - 2])
    }
  }

  useEffect(() => {
    // isMobile &&
    scrollTo("nav")
    //reset to user details and flow data when navigating back
    if (
      currentScreen.id === "worried-about-screen" ||
      currentScreen.id === "intro-screen"
    ) {
      setCurrentFlowData(initialScreens)
      storeWorriedAbout("")
    }

    //disable flow on screen change
    setFlowDisabled(true)
    const reEnable = setTimeout(() => {
      setFlowDisabled(false)

      console.log("re-enabled")
    }, animationDuration)

    return function cleanup() {
      clearTimeout(reEnable)
    }
  }, [currentScreen])

  //set flow based on user.worriedabout
  useEffect(() => {
    if (user.branch === "victim") {
      if (user.worriedAbout === "relationship") {
        setCurrentFlowData(c => [...c, ...victimRelationshipFlowData])
      } else if (user.worriedAbout === "sexual-experience") {
        setCurrentFlowData(c => [...c, ...victimSexFlowData])
      }
    }
    if (user.branch === "perpetrator") {
      if (user.worriedAbout === "relationship") {
        setCurrentFlowData(c => [...c, ...perpetratorRelationshipFlowData])
      } else if (user.worriedAbout === "sexual-experience") {
        setCurrentFlowData(c => [...c, ...perpetratorSexFlowData])
      }
    }
    if (user.branch === "friend") {
      if (user.worriedAbout === "relationship") {
        setCurrentFlowData(c => [...c, ...friendRelationshipFlowData])
      } else if (user.worriedAbout === "sexual-experience") {
        setCurrentFlowData(c => [...c, ...friendSexFlowData])
      }
    }

    if (user.worriedAbout && user.worriedAbout.length) {
      trackEvent("worriedAbout", user.worriedAbout)
    }
  }, [user.worriedAbout])

  useEffect(() => {
    if (user.branch && user.branch.length) {
      trackEvent("branch", user.branch)
      console.log(user)
    }
  }, [user.branch])

  //move forward if currentFlowData gets populated with a flow
  useEffect(() => {
    if (currentFlowData.length > initialScreens.length) {
      nextScreen()
    }
  }, [currentFlowData])

  //BACK INTERCEPTION

  // useEffect(() => {
  //   if (window.location.href.includes("questions")) {
  //     history.push("/questions", null)
  //     console.log(history)

  //     const backListener = history.listen((location, action) => {
  //       if (action === "POP") {
  //         console.log("flowDisabled:", flowDisabled)

  //         if (currentScreenIndex === 0) {
  //           console.log("backtohome")
  //           console.log(window.location)
  //           window.location.pathname = `/`
  //         } else {
  //           goBack()
  //           history.go(1)
  //           togglePopUp(false)
  //         }
  //       }
  //     })
  //     return function cleanUp() {
  //       backListener()
  //     }
  //   }
  // }, [currentScreen])

  const contextValues = {
    currentScreen,
    currentScreenIndex,
    screensLength,
    previousScreen,
    resetFlow,
    nextMove,
    goBack,
    goToScreen,
    animationDuration,
    flowDisabled,
    nextScreen,
    setCurrentFlowData,
    lastPage,
    getActionPlan,
    trackEvent
  }

  return (
    <FlowContext.Provider value={contextValues}>
      {children}
    </FlowContext.Provider>
  )
}

export default FlowContextProvider
