import React, { useState } from "react"

export const PopUpContext = React.createContext()

const PopUpProvider = ({ children }) => {
  const [isPopUpOpen, setPopUpOpen] = useState(false)
  const [message, setMesssage] = useState("")
  const [popUpType, setpopUpType] = useState("")
  const [videoUrl, setVideoUrl] = useState("")
  
  const togglePopUp = (status, type, url, message) => {
    if (type) {
      setpopUpType(type)

      if (type === "video" && url) {
        setVideoUrl(url)
      }
    }
    setPopUpOpen(status)
    setMesssage(message)
  }

  const contextValues = {
    isPopUpOpen,
    popUpType,
    togglePopUp,
    setPopUpOpen,
    videoUrl,
    message,
  }

  return (
    <PopUpContext.Provider value={contextValues}>
      {children}
    </PopUpContext.Provider>
  )
}

export default PopUpProvider
