import { useState } from "react"
import { CompositeScaleCardsData } from "utils/victimRelationshipFlowData"
const getPoints = answer => {
  let points = 0

  if (answer === "Daily") {
    points = 5
  }
  if (answer === "Once a week") {
    points = 4
  }
  if (answer === "Once a month") {
    points = 3
  }
  if (answer === "Several times") {
    points = 2
  }
  if (answer === "Only once") {
    points = 1
  }

  return points
}

const severeScale = [2, 5, 7, 15, 18, 22, 25, 26]
const physicalScale = [6, 10, 14, 17, 23, 27, 30]
const emotionalScale = [1, 4, 8, 9, 12, 19, 20, 21, 24, 28, 29]
const harassmentScale = [3, 11, 13, 16]

const arrSum = arr => {
  return arr.reduce(function(a, b) {
    return a + (b || 0)
  }, 0)
}

export const getCasScore = (responses, updatedResponses) => {
  let answers = []

  if (answers) {
    answers = responses
  }

  if (updatedResponses) {
    const matches = updatedResponses.map(newAnswer => {
      return CompositeScaleCardsData.find(
        option => option.primaryText === newAnswer.cardId
      )
    })

    const updatedResponsesIndices = matches.map(match => {
      return CompositeScaleCardsData.indexOf(match)
    })

    let newArray = []

    updatedResponsesIndices.map((number, index) => {
      return (newArray[number] = updatedResponses[index].value)
    })

    answers = newArray

    console.log("updatedResponses:", updatedResponses)
    console.log("updatedResponsesIndices:", updatedResponsesIndices)
    console.log("newArray:", newArray)
  }

  const scores = answers.map(answer => {
    const points = getPoints(answer)
    return points
  })

  //adds empty element to beginning of array
  scores.unshift(0)

  const getScaleScore = scaleArray => {
    const scaleScore = scaleArray.map(position => scores[position])
    const totalScaleScore = arrSum(scaleScore)
    if (totalScaleScore > 0) {
      return true
    }
  }

  const severe = getScaleScore(severeScale)
  const physical = getScaleScore(physicalScale)
  const emotional = getScaleScore(emotionalScale)
  const harassment = getScaleScore(harassmentScale)

  if (severe) {
    return "SC"
  }

  if (!severe && physical && (emotional || harassment)) {
    return "PE"
  }

  if (!severe && physical && !emotional && !harassment) {
    return "PO"
  }

  if (!severe && !physical && (emotional || harassment)) {
    return "EO"
  }
}
