const yesNoCardsData = [
  {
    primaryText: `You might blame yourself or feel that you did something to provoke the assault`,
  },
  {
    primaryText: `You might wonder whether you somehow gave ‘mixed signals’ about wanting to have sex`,
  },
  {
    primaryText: `You might have intense feelings of rage, fear, grief or hatred towards the perpetrator`,
  },
  {
    primaryText: `You may experience overwhelming shock or disbelief – did this really happen to me?`,
  },
  {
    primaryText: `It might be hard to get the assault out of your mind`,
  },
  {
    primaryText: `You may have nightmares or flashbacks`,
  },
  {
    primaryText: `You might feel like crying a lot`,
  },
  {
    primaryText: `You might have physical symptoms like nausea, headaches, or stomach problems`,
  },
  {
    primaryText: `You might feel numb and detached`,
  },
  {
    primaryText: `You might have trouble being intimate with a partner`,
  },
  {
    primaryText: `You might be scared about contracting a sexually transmitted infection or getting pregnant`,
  },
  {
    primaryText: `You might be unsure if what you experienced was really sexual assault`,
    
  },
  
]

const TwoColumnCheckboxScreenOptions = [
  `General Practitioner / family doctor`,
  `Police`,
  `Sexual Assault Support Services`,
  `Friends or family`,
  `Uni counsellor`,
  `JCU Sexual Misconduct Officer`,
  `No one or not sure`,
]

const ThreeColumnCheckboxScreenOptions = [
  `Somebody grabbed or touched you in a sexual way when you didn’t want them to.`,
  `You thought you were agreeing to one thing during sex, but something else ended up happening that you didn’t agree to.`,
  `You started off wanting to have sex but then changed your mind, but the person you were having sex with kept going anyway.`,
  `You felt pressured or blackmailed into having sex when you didn’t want to.`,
  `You had sex with someone in a position of power over you (e.g. a tutor) because you were worried something bad would happen if you said no (e.g. failing an assignment).`,
  `You had sex with someone because you needed a place to stay or a job.`,
  `Someone removed a condom during sex without your consent (stealthing).`,
]

export const victimSexFlowData = [
  {
    template: `ContentScreen`,
    id: `2.1.1-consent-content-1`,
    data: {
      textLeft: `<h2>Consent</h2><p>Sex is supposed to be fun and enjoyable, but sometimes sexual experiences happen that you don’t feel good about and didn’t want.</p><p>Legally, any unwanted behaviour of a sexual nature that causes fear, humiliation or intimidation is considered to be <span>sexual assault</span>.</p><p>In other words, if someone does something to you that you didn’t or couldn’t consent to (provide free agreement), it’s a crime.</p>`,
      textRight: `<p>Sexual assault includes:</p>
        <ul>
        <li>Rape (with or without physical violence);</li>
        <li>Forcing you to have oral sex (give or receive);</li>
        <li>Penetrating you with fingers or other objects;</li>
        <li>Other forms of unwanted sexual activity such as being touched or groped, being filmed during sex, being forced to watch pornography;</li>
        <li>Any other uninvited sexual behaviour that makes you feel uncomfortable, harassed or afraid.</li>
        </ul>`,
    },
  },
  {
    template: `ContentScreen`,
    id: `2.1.1-consent-content-2`,
    data: {
      textLeft: `<h2>Consent</h2><p>You can’t give free agreement if you’re being <span>threatened</span>, or if you're <span>asleep</span>, <span>drunk</span> or <span>affected by substances.</span> </p><p>We often hear about ‘No means no!’ but sometimes consent is complicated. You might feel like you can’t say no, or that saying no would be unsafe, so instead you say nothing. Silence is <span>not the same as consent</span>.</p><p>Just because you didn’t say ‘No’ or didn’t fight back doesn’t mean you consented.</p>`,
      textRight: `<p>On this website we are talking about sexual experiences you might have had as an adult (over the age of 16) with someone you were not in a relationship with.</p><p>If you have experienced sexual abuse as a child (under 16), this website might help you <a href='http://www.blueknot.org.au' >www.blueknot.org.au</a>.</p><p>If you have had an unwanted sexual experience in a relationship, you can visit the relationships section of this website once you’ve finished this module.</p>`,
    },
  },
  {
    template: `CheckboxScreen`,
    id: `2.1.1.1-unwanted-sexual-experiences-checkbox`,
    data: {
      heading: `Unwanted sexual experiences`,
      popUp: {
        tall: true,
        type: `feedback`,
        heading: `Feedback`,
        msg1: `<h2>Answer</h2>
        <p>Actually, all of these
        experiences are forms of
        sexual assault. It is <span> never
        OK </span> for someone to touch
        you in a sexual way
        without consent, and it is <span>never OK</span> for someone to
        pressure you into having
        sex. If someone is using
        your situation as a way to 
        force you to have sex with
        them, that’s called
        <span>coercion</span> and it’s illegal. You are in charge of your own body and you have a right to have your sexual decisions respected.</p>`,
      },
      textRight: `<p>Most people hear the words ‘sexual assault’ and think of rape and being physically forced or held down. However, there are also lots of grey areas when it comes to having sex. Below are some common things that can happen.</p>
      <span>Which ones do you think count as sexual assault?</span>
        `,
      columns: 3,
      options: ThreeColumnCheckboxScreenOptions,
    },
  },
  {
    template: `YesNoCardsScreen`,
    id: `2.1.1.2-feelings-yes-no-cards`,
    data: {
      textLeft: `<h2>Feelings</h2><p>You may have many complicated feelings when you have been sexually assaulted or had an unwanted sexual experience.</p><p>Please tick if you are feeling any of these:</p>`,
      cardsData: yesNoCardsData,
    },
  },
  {
    template: `ContentScreen`,
    id: `2.1.1.2-feelings-content`,
    data: {
      textLeft: `<h2>Feelings</h2><p>These feelings and reactions are common and normal.</p><p>Remember, your feelings might change over time as well. It may take days, weeks or months for some feelings to catch up with you. There is no ‘right’ or ‘wrong’ way to feel; every response is valid.</p><p>The important thing to remember is that it is never your fault. Nobody deserves to be assaulted or to be pressured into doing something they don’t feel comfortable with.</p><p>With time, it’s likely you will feel better, but in the meantime, this website will suggest some things you can do to help take care of yourself.</p>`,
      textRight: `<p>If you are feeling persistently down or low, anxious or panicky, or are experiencing flashbacks or reliving the sexual assault, it’s important to see someone for help.</p><p>Depression, anxiety and stress are very common. They can have a negative impact on many areas of your life, but they can also be delt with very effectively with the right support.</p><p>Remember, if you are thinking about hurting yourself, please call Lifeline on <a href:'tel:131114'>13 11 14</a> to speak to someone confidentially.</p>`,
    },
  },
  {
    template: `VideoScreen`,
    id: `2.1.1.2-video-1`,
    data: {},
  },
  {
    template: `VideoScreen`,
    id: `2.1.1.3-video-2`,
    data: {
      double: true,
    },
  },
  {
    template: `CheckboxScreen`,
    id: `2.1.1.4-talking-about-it-checkbox`,
    data: {
      heading: `Talking about it`,
      textRight: `<p>Here is a list of people you might choose to talk to after an unwanted sexual experience or sexual assault. Tick the boxes below to find out how these people or services can help</p>
      <span>Please choose all the options that apply.</span>`,
      internationalPopUp: true,
      columns: 2,
      options: TwoColumnCheckboxScreenOptions,
    },
  },
  {
    template: `TalkingFeedbackScreen`,
    id: `2.1.1.4-talking-about-it-feedback`,
    data: {
      sections: [
        {
          title: `General Practitioner / family doctor`,
          body: `
          <p>A trusted GP can help in several ways if you have been sexually assaulted. If the assault happened within the last 72 hours, the GP can provide emergency contraception and can organise testing to make sure you haven't contracted any sexually transmitted infections (STIs). They will be able to offer you resources that you can contact if you want, such as the specialist Sexual Assault Support Services (see Action Plan for details).</p>
          <p>Whether or not you want to report the assault (or if it happened longer than 72 hours ago), the GP can still help. A good GP can be a safe person to talk to. They can provide you with a medical certificate if you need time off from work. They can also refer you to a mental health professional (including helping you access 10 free sessions if you have a Medicare card) to deal with stress, trauma, or other difficult feelings.</p>
          <p>If you have become pregnant as a result of the sexual assault, the GP can also help discuss your options. Remember, everything you discuss with the GP is confidential.</p>`,
        },
        {
          title: `Police`,
          body: `
          <p>If you have concerns for your immediate safety, call Triple Zero (000).</p>
          <p>The best place to report a sexual assault is your closest Police Station. Detectives from the Criminal Investigation Branch (CIB) from the Queensland Police Service will respond to your report.</p>
          <p>This is what might happen if you decide to make a report or talk to police:</p>

          <p>In Townsville, a Sexual Assault Response Team (SART) will respond. The SART team is made up of a Sexual Assault Support Service worker, a Detective, and a Clinical Forensic Nurse if a Clinical Forensic Medical examination is required.</p>
          
          <p>In other parts of Queensland, ask the police to put you in contact with the local Sexual Assault Support Service.</p>
          
          <p>You can also talk to a Sexual Assault Support Service without having any contact with police at all, if you don’t want to (see the Action Plan section of this website for details about how to contact these services).</p>

          <p>The police may ask you to undergo a medical examination. It is your choice whether to do this or not. The purpose of a medical examination is to collect and document physical evidence of the crime. The test may involve things like a blood test, vaginal or anal swabs, and an examination of any possible physical injuries. If you are not sure you want to proceed with an investigation, you can have a medical examination anyway, and make up your mind at a later date.</p>

          <p>You can discuss your options thoroughly with police and/or the Sexual Assault Support Service. You can take time to make your decision.</p>

          <p>You can make a report to Police straight away, or at any time afterwards.</p>

          <p>Be aware that reporting, and making a statement, can take a long time, and be an emotional experience. It can be really helpful to have a friend go with you for support. Investigations can also take some time, so try to build a support network. This may include a support worker from a Sexual Assault Support Service, friends and/or family, who can listen to you and be supportive.</p>
          `,
        },
        {
          title: `Sexual Assault Support Services`,
          body: `
          <p>Sexual Assault Support Services  provide specialist support to people who have experienced any type of sexual assault. They provide free, confidential support, counselling and advocacy. The Sexual Assault Support Services have support workers who can offer ways to help you manage the physical and emotional affects you may be experiencing. They can explain and assist with legal and medical options, including any emergency health care or forensic examinations, and any decisions about reporting to police. Even if it has been months or years after an assault, you can still contact them for support.</p>
          <p>You can find the closest support service to you by looking in the Action Plan section of this website. There are services that provide: 24-hour crisis support and access to medical attention and legal information following an assault; individual counselling and advocacy support; support groups; 24-hour telephone counselling and support; information and referral.</p>
          `,
        },
        {
          title: `Friends or family`,
          body: `
          <p>Sometimes just telling someone about a sexual assault can help, even if you don't want the person to do anything about it. A supportive friend or family member should listen non-judgementally and offer assistance without being pushy. You may find that others around you have had similar experiences and can understand what you’re going through. Even if they haven’t, their support can still be really helpful.</p>
          <p>A friend or family member can be a good person to come with you if you have been recently assaulted and need medical care, or want to report the assault to police. They can also make sure that you practice good self-care to help manage stress, anxiety, or other negative feelings. For friends who are students at James Cook University, there is information on the <a rel='noopener noreferrer' target='_blank' href='https://www.jcu.edu.au/safety-and-wellbeing/sexual-harassment-and-sexual-assault/finding-support'>JCU Safety and Wellbeing</a> webpage about how they can support you.</p>
          `,
        },
        {
          title: `Uni counsellor`,
          body: `
            <p>The university provides free, confidential, short term counselling to currently enrolled domestic and international students. You can either call to book an appointment or book online. Appointments can be by phone, Zoom (online video call software), or face to face on either the JCU Cairns, Nguma-bada campus, or JCU Townsville, Bebegu Yumba campus.</p>
            <p>A counsellor or psychologist is a great person to talk to as they are impartial and confidential. They will not tell the university about any issues you may be having, this is not their role. They can support you to make a report to the university if you wish. They may recommend that you seek more specialised services if they feel that you are in danger or you need some help. They will be able to help you access these services while you are in their office if you feel unsafe doing this on your own. </p>
            <p>Click on the link below to book an appointment online.</p>

            <a href="https://www.jcu.edu.au/student-equity-and-wellbeing/wellbeing/counselling" rel="noopener noreferrer" target="_blank">https://www.jcu.edu.au/student-equity-and-wellbeing/wellbeing/counselling</a>
          `
        },
        {
          title: `JCU Sexual Misconduct Officer`,
          body: `

          <p>You can talk confidentially with a JCU Sexual Misconduct Officer. Your conversation with them may cover topics like: your safety, your support requirements, and what referrals might help you. They can assist you to connect with the Sexual Assault Support Services, and can work through what supports you may need to continue with studying or working (e.g. implementing safety plans; short-term emergency housing; special consideration for your studies). Talking with a Sexual Misconduct Officer does not mean you will have to report to police or that there will be any further action taken – this is always up to you. The Sexual Misconduct Officer will respect your agency and decision making, and will act in line with your wishes.</p>

          <p>You can connect with a Sexual Misconduct Officer in different ways: via email or phone (contact details <a rel="noopener noreferrer" target="_blank" href="https://www.jcu.edu.au/safety-and-wellbeing/sexual-harassment-and-sexual-assault/sexual-misconduct-officers">here</a>) or by submitting an online report form (available <a rel="noopener noreferrer" target="_blank" href="https://www.jcu.edu.au/safety-and-wellbeing/sexual-harassment-and-sexual-assault/make-a-report">here</a>).</p>
          `,
        },
     
        {
          title: `No one or not sure`,
          body: `
          <p>Talking to someone may not seem like the right thing to do, and that's okay. Not everyone wants to share an experience of sexual assault, especially if it happened a long time ago and they don't want to do anything about it. However, if you do start to feel yourself experiencing flashbacks, or feeling anxious, sad, depressed or stressed out, it's important to seek help early.</p>
          <p>Sometimes the impacts of trauma can show up years after the experience, so keeping a close eye on your mental health is a good idea.</p>
          <p>In the meantime, it might be helpful to keep a journal. Sometimes, the first person you need to tell is yourself, and writing things down might help. Remember to be kind to yourself and know that there are people and supports who will respond when you are ready.</p>
          `,
        },
      ],
    },
  },
  {
    template: `ContentScreen`,
    id: `2.1.1.4-Content`,
    data: {
      textLeft: `<h2>Talking about it</h2><p>Talking about your experience can be helpful. Research suggests that it might help avoid or reduce some of the mental health impacts. However, remember that <span>you</span> are in control of how much you disclose or share with others.</p>`,
      textRight: `<p>You do not have to disclose the <span>details</span> about a sexual assault or unwanted sexual experience in order to get help.</p><p>A supportive listener should allow you to disclose <span>only as much as you feel comfortable.</span></p>`,
    },
  },
]
